/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';

import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { GuideDialogComponent } from './guide-dialog/guide-dialog.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { VendorSelectorComponent } from './vendor-selector/vendor-selector.component';
import { ProgressBarRendererComponent } from './progress-bar-renderer/progress-bar-renderer.component';
import { VendorWithLogoRendererComponent } from './vendor-with-logo-renderer/vendor-with-logo-renderer.component';
import { DayRendererComponent } from './day-renderer/day-renderer.component';
import { DialogIframeComponent } from './dialog-iframe/dialog-iframe.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,

    MatDatepickerModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatIconModule,
    OverlayModule,
  ],
  declarations: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DateSelectorComponent,
    GuideDialogComponent,
    DateTimeSelectorComponent,
    DateTimeRangePickerComponent,
    VendorSelectorComponent,
    ProgressBarRendererComponent,
    VendorWithLogoRendererComponent,
    DayRendererComponent,
    DialogIframeComponent,
  ],
  exports: [
    DateSelectorComponent,
    DateTimeSelectorComponent,
    DateTimeRangePickerComponent,
    VendorSelectorComponent,
    ProgressBarRendererComponent,
    VendorWithLogoRendererComponent,
  ]
})
export class SharedModule { }
