/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { unifiedOrderVendorMappings } from '../../core/1/string-map';

export interface VendorCount {
  [vendor: string]: string;
}

@Component({
  selector: 'app-vendor-selector',
  templateUrl: './vendor-selector.component.html',
  styleUrls: ['./vendor-selector.component.scss']
})
export class VendorSelectorComponent implements OnInit, OnDestroy {
  @Input() public vendorCount: VendorCount;
  @Output() public vendorChangeEvent = new EventEmitter<string[]>();

  public vendorForm: FormGroup;
  public vendorItems: {
    key: string;
    name: string;
  }[] = [];

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    const vendors = ['baemin', 'coupangeats', 'yogiyo', 'ghostkitchen', 'other'];

    this.vendorItems = vendors.map(vendor => ({
      key: vendor,
      name: unifiedOrderVendorMappings[vendor]
    }));

    const formGroup = Object.fromEntries(vendors.map(vendor => [vendor, false]));
    this.vendorForm = this.fb.group(formGroup);

    this.observeVendorFormChange();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private observeVendorFormChange() {
    this.subscription?.unsubscribe();
    this.subscription = this.vendorForm.valueChanges.subscribe((e: { [key: string]: string }) => {
      const vendors = Object.entries(e).filter(([_, v]) => v).map(([vendor, _]) => vendor);
      this.vendorChangeEvent.emit(vendors);
    });
  }
}
