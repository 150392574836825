/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format, isAfter } from 'date-fns';
import { ko } from 'date-fns/locale';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { NoticeDoc } from '../../schema/3/schema';

import { UserService } from '../2/user.service';
import { RoomService } from '../3/room.service';

const collectionPath = 'notice';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  public currentRoomKey: string;
  public noticeDocs: NoticeDoc[] = [];
  public lastestNoticeDocsSubject = new BehaviorSubject<NoticeDoc[]>([]);

  private subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private roomService: RoomService
  ) {
  }

  public observe(roomKey: string) {
    if (this.currentRoomKey === roomKey) {
      return;
    }

    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => (
      ref.where('showCEO', '==', true)
        .where('whereIn', 'in', [organization, this.roomService.rooms[roomKey].site])
        .where('state', '==', 'show')
    );

    const collection = this.db.collection<NoticeDoc>(collectionPath, queryFn);
    // roomKey가 달라지는 경우 새로구독한다.
    this.subscription?.unsubscribe();
    this.subscription = collection.valueChanges().subscribe(docs => {
      this.noticeDocs = docs
        .sort((a, b) => a.pinned && !b.pinned ? -1 : !a.pinned && b.pinned ? 1 : isAfter(a._timeCreate.toDate(), b._timeCreate.toDate()) ? -1 : 1)
        .map(doc => ({
          ...doc,
          _ui: {
            date: format(doc._timeCreate.toDate(), 'yyyy.MM.dd(ccc) HH:mm', { locale: ko }),
            isRead: !!doc.readBy[roomKey],
          }
        }));
      this.lastestNoticeDocsSubject.next(this.noticeDocs);
    });

    this.currentRoomKey = roomKey;
  }
}
