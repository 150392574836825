<div class="selectCondition">
  <mat-form-field>
    <mat-label>지점 :</mat-label>
    <mat-select [formControl]="siteControl" required>
      <mat-option *ngFor="let site of sites" [value]="site._id">{{ trimOrganization(site.siteName) }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>계정 :</mat-label>
    <mat-select [formControl]="userControl" required>
      <mat-option *ngFor="let user of users" [value]="user">
        {{user.email}} ({{ trimSite(roomServcie.rooms[user.room].name) }} {{ roomServcie.rooms[user.room].shopName }})
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
