/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
  remoteceo: '원격사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제'
};

export const unifiedOrderVendorMappings = {
  baemin:       '배민',
  foodfly:      '푸플',
  yogiyo:       '요기요',
  ubereats:     '우버이츠',
  ddingdong:    '띵동',
  ddangyo:      '땡겨요',
  coupangeats:  '쿠팡이츠',
  shuttle:      '셔틀',
  ghostkitchen: '고스트키친',
  other:        '기타',
};

export const unifiedOrderChannelMappings = {
  app:  '앱',
  tel:  '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY: '배달',
  COUPANG: '쿠팡이츠',
  BAERA: '배민1',
  YOGIYO: '익스프레스',
  TAKEOUT: '포장',
  HERE: '매장식사',
  FOODFLY: '푸드플라이',
  DDINGDONG: '띵동',
  UBER:      '우버이츠',
  SHUTTLE:   '셔틀'
};

export const unifiedOrderCreatedByMappings = {
  manual: '직접입력'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED:    '신규',
  ACCEPTED:  '주문접수',
  CLOSED:    '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED:  '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED:  '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금',
  AT_DEST_MIXED: '후불분할'
};

export const foodflyAcceptStatusMappings = {
  0:  '신규',
  3:  '주문접수',
  5:  '조리완료',
  98: '취소',
  99: '주문거절'
};

export const foodflySendStatusMappings = {
  1: '배차대기',
  2: '배차완료',
  3: '업장도착',
  4: '픽업',
  5: '배달완료',
  6: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const deliveryVendorMappings = {
  vroong: '부릉',
  run2u: '런투유',
  spidor: '스파이더',
  barogo: '바로고',
  logiall: '생각대로',
  ghokirun: '고키런',
  manna: '만나플래닛',
  baera: '배라',
  coupang: '쿠팡',
  yogiyo: '요기요',
  zendeli: '젠딜리'
};

export const unifiedDeliveryStatusCodeMappings = {
  10: '접수',
  20: '배차',
  30: '픽업',
  40: '배달완료',
  50: '취소'
};


// 2021-08-22: 인증없이 아래 요청을 보내면 확인 가능
// refer: https://ceo.baemin.com/route/adcenter/v2/ad-categories
// displayRadius도 확인 가능
export const baeminAdCategoryMappings = {
  KOREAN: '한식',
  SNACK: '분식',
  JAPANESE: '돈까스·회·일식',
  CHICKEN: '치킨',
  PIZZA: '피자',
  ASIAN_WESTERN: '아시안·양식',
  ASIAN: '아시안',
  WESTERN: '양식',
  CHINESE: '중식',
  BAEKBAN: '백반·죽·국수',
  MEAT: '고기·구이',
  JOKBAL: '족발·보쌈',
  NIGHTSNACK: '야식',
  ZZIM_TANG: '찜·탕',
  DOSIRAK: '도시락',
  CAFE: '카페·디저트',
  FASTFOOD: '패스트푸드',

  BAEMIN_1_MEAT: '고기·구이',
  BAEMIN_1_BAEKBAN: '백반·죽·국수',
  BAEMIN_1_ZZIM_TANG: '찜·탕·찌개',
  BAEMIN_1_SNACK: '분식',
  BAEMIN_1_CAFE: '카페·디저트',
  BAEMIN_1_JAPANESE: '돈까스·회·일식',
  BAEMIN_1_WESTERN: '양식',
  BAEMIN_1_ASIAN: '아시안',
  BAEMIN_1_JOKBAL: '족발·보쌈',
  BAEMIN_1_PIZZA: '피자',
  BAEMIN_1_CHICKEN: '치킨',
  BAEMIN_1_CHINESE: '중식',
  BAEMIN_1_BURGER: '버거',
};

export const vendorColorMappings = {
  ghostkitchen: 'hsl(348, 98%, 84%)', // 요기요와 구분하기 위해 sub컬러 사용
  // 각각 원본 컬러에서 밝기를 조정했다.
  baemin: 'hsl(178, 64%, 56%)',
  yogiyo: 'hsl(341, 100%, 69%)',
  coupangeats: 'hsl(24, 58%, 56%)',
  // baemin: 'hsl(178, 64%, 46%)',
  // yogiyo: 'hsl(341, 100%, 49%)',
  // coupangeats: 'hsl(24, 58%, 36%)',
};
