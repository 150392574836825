/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

//
// ref: https://developers.google.com/gtagjs/reference/api
//

// index.html의 config 값과 일치해야 한다.
const trackID = 'UA-147991513-3';

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  constructor() { }

  /**
   * URL이 변경되면 호출해 준다.
   * SPA라서 필요하다.
   *
   */
  page(url: string) {
    (window as any).gtag('config', trackID, { page_path: url });
  }

  /**
   * ref: https://stackoverflow.com/questions/50231721/how-to-track-utm-source-in-google-analytics-using-gtag
   */
  campaign(medium: string, source: string, name?: string) {
    const campaign = name ? {
      medium,
      source,
      name
    } : {
      medium,
      source
    };

    (window as any).gtag('config', trackID, { campaign });
  }

  event(action: string, category: string, label: string, value?: number) {
    const event = value ? {
      event_category: category,
      event_label: label,
      value
    } : {
      event_category: category,
      event_label: label,
    };

    (window as any).gtag('event', action, event);
  }
}
