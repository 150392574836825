/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { UserDoc } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  /** ceoMaster인 경우에는 businessStartDate, businessEndDate는 로그인 사용자의 값과 다른다. */
  public user: UserDoc;
  public latestUserSubject = new BehaviorSubject<UserDoc>(null);

  /**
   * ceoMaster인 경우에 user의 businessStartDate, businessEndDate를 덮어쓸 때만 사용한다.(중간 저장용)
   */
  private targetBusinessStartDate: string;
  private targetBusinessEndDate: string;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  public get organization() {
    if (this.user?.organization === undefined) {
      alert('필수정보(organization)가 없습니다. 개발자에게 알려주세요.');
    }
    return this.user.organization;
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observe(email: string) {
    console.log(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`user/${email}`);

    docRef.valueChanges().subscribe(async doc => {
      this.user = doc;

      if (doc?.roleCeoMaster === true) {
        if (this.targetBusinessStartDate === undefined) {
          // 현재 room에 대한 businessStartDate를 가져와서 this.user의 내용을 바꿔치기한다.
          await this.updateBusinessStartEndDateForCeoMaster(doc);
        }
        // 로그인 계정 값이 아닌 UI에서 선택한 값으로 덮어쓴다. 그다지 좋은 방식은 아니다.
        this.user.businessStartDate = this.targetBusinessStartDate;
        this.user.businessEndDate = this.targetBusinessEndDate;
      }

      console.log(`user = ${doc.email}, ${this.user.businessStartDate} ~ ${this.user.businessEndDate}`);
      this.latestUserSubject.next(this.user);
    }, error => {
      this.utilService.toastrError(`UserService:observe에서 에러 발생 : ${error}`);
    });
  }

  /**
   * 마스터계정의 경우에 user를 변경하면 해당 user에 해당하는 businessDate를 적용한다.
   */
  public async updateBusinessStartEndDateForCeoMaster(newUser: UserDoc) {
    if (this.user?.roleCeoMaster === true) {
      this.targetBusinessStartDate = newUser.businessStartDate;
      this.targetBusinessEndDate = newUser.businessEndDate;

      if (this.user) {
        this.user.businessStartDate = this.targetBusinessStartDate;
        this.user.businessEndDate = this.targetBusinessEndDate;
      }
      console.log(`[${newUser.email}] businessStartDate = ${this.targetBusinessStartDate}`);
      console.log(`[${newUser.email}] businessEndDate = ${this.targetBusinessEndDate}`);
    }
  }

  /**
   * user의 role이 ceo, ceostat인 경우를 감시한다.
   */
  public observeCeoUser() {
    const queryFn: QueryFn = ref => {
      return ref
      .where('organization', '==', this.organization)
      .where('role', 'in', ['ceostat', 'ceo']);
    };

    const collection = this.db.collection<UserDoc>('user', queryFn);
    return collection.valueChanges();
  }

}
