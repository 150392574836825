/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/2/auth.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  ngOnInit() {
    this.authForm = this.fb.group({
      email: ['', [Validators.email]],
      password: ''
    });
  }

  // emailValidation() {
  //   return (control: FormControl): ValidationErrors | null => {
  //     if (control.parent === null) {
  //       return null;
  //     }

  //     const emailForm = control.parent;
  //     const email = emailForm.get('email').value;
  //     const reg = /@toe\.cloud/;

  //     if (reg.test(email)) {
  //       return { reason: '@toe.cloud는 사장님 사이트에서 사용할 수 없습니다. @ceo.stat 계정으로 로그인 해주세요.' };
  //     }
  //   };
  // }

  onSubmit() {
    const promise = this.authService.signinWithEmailAndPassword(this.authForm.value.email, this.authForm.value.password);

    promise.then(value => {
      console.log('navigate');
      this.router.navigate(['/']);
    }, err => {
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
    });
  }
}
