/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'unified-order'
  },
  {
    path: 'unified-order',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/unified-order/unified-order.module').then(m => m.UnifiedOrderModule)
  },
  {
    path: 'monthly-invoice',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/monthly-invoice/monthly-invoice.module').then(m => m.MonthlyInvoiceModule)
  },
  {
    path: 'order-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/order-stat/order-stat.module').then(m => m.OrderStatModule)
  },
  {
    path: 'hour-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/hour-stat/hour-stat.module').then(m => m.HourStatModule)
  },
  {
    path: 'by-day-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/by-day-stat/by-day-stat.module').then(m => m.ByDayStatModule)
  },
  {
    path: 'excluding-option-menu-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/excluding-option-menu-stat/excluding-option-menu-stat.module').then(m => m.ExcludingOptionMenuStatModule)
  },
  {
    path: 'including-option-menu-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/including-option-menu-stat/including-option-menu-stat.module').then(m => m.IncludingOptionMenuStatModule)
  },
  {
    path: 'baemin-operating-ad-campaign',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/baemin-operating-ad-campaign/baemin-operating-ad-campaign.module').then(m => m.BaeminOperatingAdCampaignModule)
  },
  {
    path: 'delivery-fee',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/delivery-fee/delivery-fee.module').then(m => m.DeliveryFeeModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'origin-description',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/origin-description/origin-description.module').then(m => m.OriginDescriptionModule)
  },
  {
    path: 'unified-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/unified-delivery/unified-delivery.module').then(m => m.UnifiedDeliveryModule)
  },
  {
    path: 'notice',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/notice/notice.module').then(m => m.NoticeModule)
  },
  {
    path: 'daily-baemin-operating-ad-campaign/:shopNo',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/daily-baemin-operating-ad-campaign/daily-baemin-operating-ad-campaign.module').then(m => m.DailyBaeminOperatingAdCampaignModule)
  },
  {
    path: 'daily-history',
    loadChildren: () => import('./pages/daily-history/daily-history.module').then(m => m.DailyHistoryModule)
  },
  {
    path: 'unified-shop/:service',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/unified-shop/unified-shop.module').then(m => m.UnifiedShopModule)
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
