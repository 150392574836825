/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { add, format, isAfter, isBefore, set, sub } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { UtilService } from '../../core/1/util.service';
import { DateRangeButton } from '../../core/1/common';
import { DateTimeRangePickerComponent, DATE_TIME } from '../date-time-range-picker/date-time-range-picker.component';

/**
 * 다른 컴포넌트에서 observe할 조회 날짜의 interface
 */
export interface SearchDateTime {
  startDateTime: Date;
  endDateTime: Date;
}

@Component({
  selector: 'app-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss']
})
export class DateTimeSelectorComponent implements OnInit {
  public rangeLimit: Duration = { days: 8 }; // 8일
  public rangeLimitText = '8일';
  @Input('rangeLimit') set _rangeLimit([rangeLimit, rangeLimitText]: [Duration, string]) {
    this.rangeLimit = rangeLimit;
    this.rangeLimitText = rangeLimitText;
  }

  // true: 달력외에 시간선택까지 할 수 있다
  @Input() useTimeSelector: boolean;
  // true: input에 시간에 대해 표시한다.
  @Input() displayTime = true;

  @Input('dateInitTime') set dateInitTime([startDate, endDate]: [Date, Date]) {
    // 범위 내의 올바른 데이터가 온다고 가정한다.
    this.setNewDateRange(startDate, endDate);
  }

  /**
   * 버튼의 설정사항 목록 - 각 페이지에서 상황에 따라 필요한 설정을 파라미터로 넘겨서 버튼 목록을 구성한다.
   * buttonName 버튼이름
   * startDateDuration 시작일에 대해 "현재시각"을 기준으로 duration 정보
   * endDateDuration: 종료일에 대해 "시작일"을 기준으로 duration 정보
   *
   * 예시)
   * {
   *   buttonName: '일주일',                      // 화면에 노출될 버튼 이름
   *   startDateDurationFromToday: {            // 시작일에 대해 "오늘"로부터의 duration 설정
   *     days: 7                                // 양수 7 -> 7일 '전'
   *   },
   *   endDateDurationFromStartDate: {          // 종료일에 대한 "시작일"로부터의 duration 설정 설정
   *     days: 14                               // 양수14 -> 시작일로부터 14일 '후'
   *   },
   *   startDateSetOptions: {                   // 시작일에 대해 명시적으로 특정할 경우. 이경우는 00:00:00
   *     hours: 0,                              // optional. 값을 주지 않는다면 현재 날짜와 시각이 그대로 유지된다.
   *     minutes: 0,
   *     seconds: 0
   *   },
   *   endDateSetOptions: {                     // 종료일에 대해 명시적으로 특정할 경우. 이경우는 00:00:00
   *     hours: 0,                              // optional. 값을 주지 않는다면 현재 날짜와 시각이 그대로 유지된다.
   *     minutes: 0,
   *     seconds: 0
   *   }
   * }
   *
   * 날짜만 사용하는 경우: useTimeSelector: false, displayTime: false
   * 시작일은 00:00:00 종료일은 23:59:59로 설정해주어야 그 날짜의 하루 모두를 포함할 수 있다.
   *     {
   *  buttonName: '오늘',
   *   startDateDurationFromToday: {
   *     days: 0
   *   },
   *   endDateDurationFromStartDate: {
   *     days: 6
   *   },
   *   startDateSetOptions: {
   *     hours: 0,
   *     minutes: 0,
   *     seconds: 0
   *   },
   *   endDateSetOptions: {
   *     hours: 23,
   *     minutes: 59,
   *     seconds: 59
   *   }
   * }
   */
  @Input() public buttons: DateRangeButton[];

  @Output() dateTime = new EventEmitter<SearchDateTime>();

  @ViewChild('startDateTimePicker', { static: false, read: ElementRef }) startDateTimePickerRef: ElementRef;
  @ViewChild('endDateTimePicker', { static: false, read: ElementRef }) endDateTimePickerRef: ElementRef;

  /** 날짜에 변경이 있거나 유효한 날짜범위가 지정되어야 '적용'버튼이 활성화된다. */
  public isDateTimeEmitDisabled = true;

  /** 선택한 날짜의 현재 상태이며, emit의 대상이다. */
  public searchDateTime: SearchDateTime = {
    startDateTime: new Date(),
    endDateTime: new Date()
  };

  public startDateTimeControl = new FormControl();
  public endDateTimeControl = new FormControl();

  /** custom selector와 날짜를 주고 받기 위한 BehaviorSubject */
  private dateTimeSubject: BehaviorSubject<{
    date: string,
    time: string,
    dateInput: string,
    useTimeSelector: boolean
  }>;

  constructor(
    private dateAdapter: DateAdapter<any>,
    private utilService: UtilService,
    private overlay: Overlay
  ) {
    this.dateAdapter.setLocale('toe-kr');
  }

  ngOnInit(): void {
  }

  public onSearchButtonClick() {
    // 변경사항을 알린다.
    this.dateTime.emit(this.searchDateTime);
    // 버튼은 다시 비활성화
    this.isDateTimeEmitDisabled = true;
  }

  public dateToString(date: Date) {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  public setQuickButtonRange(button: DateRangeButton) {
    // 기준점은 오늘이다.
    const today = new Date();

    // 시작일, 종료일 지정
    let startDate = sub(today, button.startDateDurationFromToday);
    let endDate = add(startDate, button.endDateDurationFromStartDate);

    // option에 따라 보정
    if (button.startDateSetOptions) {
      startDate = set(startDate, button.startDateSetOptions);
    }

    if (button.endDateSetOptions) {
      endDate = set(endDate, button.endDateSetOptions);
    }

    this.setNewDateRange(startDate, endDate);
  }

  public showDateTimeRangePicker(dateInput: 'start' | 'end') {
    // 화면사이즈
    const viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // element의 위치
    const elementRef = dateInput === 'start' ? this.startDateTimePickerRef : this.endDateTimePickerRef;
    const elementRefX = elementRef.nativeElement.getBoundingClientRect().x;
    const elementPositionX = viewPortWidth < elementRefX + 386 ? elementRefX : 0;

    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(dateInput === 'start' ? this.startDateTimePickerRef : this.endDateTimePickerRef)
        .withPositions([{
          originX: 'start',
          originY: 'bottom',
          offsetX: elementPositionX * -1,
          overlayX: 'start',
          overlayY: 'top'
        }])
        .withPush(false),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });

    // overlay 제거 시그널
    const overlayDestroySignal = new Subject<void>();

    // refer: https://stackoverflow.com/questions/51520584/what-is-observable-observer-and-subscribe-in-angular
    overlayRef.backdropClick()
      .pipe(takeUntil(overlayDestroySignal))
      .subscribe(
        () => { overlayRef.detach(); }
      );

    const dateTimePickerPortal = new ComponentPortal(DateTimeRangePickerComponent, null, this.createDateTimeInjector(dateInput));
    const componentRef = overlayRef.attach(dateTimePickerPortal);

    this.dateTimeSubject
      .pipe(takeUntil(overlayDestroySignal))
      .subscribe(data => {
        const newSelectedDate = this.displayTime ? `${data.date} ${data.time}` : data.date;
        const dateFormat = this.displayTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';

        if (data.dateInput === 'start' && (format(this.searchDateTime.startDateTime, dateFormat) !== newSelectedDate)) {
          // searchDateTime은 항상 시간 정보를 유지한다.
          this.searchDateTime.startDateTime = new Date(`${data.date}T${data.time}+09:00`);
          this.startDateTimeControl.setValue(newSelectedDate);
          this.checkSafeRange(this.searchDateTime.startDateTime, this.searchDateTime.endDateTime, dateInput);
        } else if (data.dateInput === 'end' && (format(this.searchDateTime.endDateTime, dateFormat) !== newSelectedDate)) {
          // searchDateTime은 항상 시간 정보를 유지한다.
          this.searchDateTime.endDateTime = new Date(`${data.date}T${data.time}+09:00`);
          this.endDateTimeControl.setValue(newSelectedDate);
          this.checkSafeRange(this.searchDateTime.startDateTime, this.searchDateTime.endDateTime, dateInput);
        } else {
          // error
        }
      });

    // 오버레이 창이 닫힐 때 관련된 subscribe를 모두 끊어준다
    componentRef.onDestroy(() => {
      overlayDestroySignal.next();
      overlayDestroySignal.unsubscribe();
    });
  }

  /**
   * rangeLimit을 만족하는지, 순서를 지키는지 등 확인
   */
  private checkSafeRange(newStartDate: Date, newEndDate: Date, dateInput: 'start' | 'end') {
    const newSearchDate: SearchDateTime = {
      startDateTime: newStartDate,
      endDateTime: newEndDate,
    };

    // 1. startDate > endDate ?
    // TODO: 시간:분은 제멋대로라서 같은 날을 지정한 경우에 불필요한 메시지가 보인다.
    if (isAfter(newStartDate, newEndDate)) {
      this.utilService.toastrInfo('시작일이 종료일을 넘을 수 없습니다.', '[기간 확인]', 3000);
      this.isDateTimeEmitDisabled = true;
      return;
    }

    // rangeLimit에서 벗어나는지 확인
    if (dateInput === 'start') {
      const rangeEndDate = add(newSearchDate.startDateTime, this.rangeLimit as Duration);

      if (isAfter(newEndDate, rangeEndDate)) {
        this.utilService.toastrInfo(`선택한 기간이 제한 범위(${this.rangeLimitText})를 벗어납니다.`, '[기간 확인]', 3000);
        this.isDateTimeEmitDisabled = true;
        return;
      }
    } else {
      const rangeStartDate = sub(newSearchDate.endDateTime, this.rangeLimit as Duration);

      if (isBefore(newStartDate, rangeStartDate)) {
        this.utilService.toastrInfo(`선택한 기간이 제한 범위(${this.rangeLimitText})를 벗어납니다.`, '[기간 확인]', 3000);
        this.isDateTimeEmitDisabled = true;
        return;
      }
    }

    // 적용 버튼을 활성화!
    this.isDateTimeEmitDisabled = false;
  }

  private setNewDateRange(startDate: Date, endDate: Date) {
    const dateFormat = this.displayTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';

    // 날짜 설정
    this.searchDateTime.startDateTime = startDate;
    this.startDateTimeControl.setValue(format(startDate, dateFormat));
    this.searchDateTime.endDateTime = endDate;
    this.endDateTimeControl.setValue(format(endDate, dateFormat));

    // 변경사항을 알린다.
    this.dateTime.emit(this.searchDateTime);
    // 버튼은 다시 비활서화
    this.isDateTimeEmitDisabled = true;
  }

  private createDateTimeInjector(dateInput: 'start' | 'end') {
    const date = dateInput === 'start' ? this.searchDateTime.startDateTime : this.searchDateTime.endDateTime;
    this.dateTimeSubject = new BehaviorSubject({
      date: format(date, 'yyyy-MM-dd'),
      time: format(date, 'HH:mm:ss'),
      dateInput,
      useTimeSelector: this.useTimeSelector
    });

    return Injector.create({ providers: [{ provide: DATE_TIME, useValue: this.dateTimeSubject }] });
  }

}
