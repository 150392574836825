/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SiteDoc, UserDoc } from '../schema/3/schema';

import { AccountSelectorCreatedService } from '../core/1/account-selector-created.service';
import { trimSite, trimOrganization } from '../core/1/util';
import { UserService } from '../core/2/user.service';
import { RoomService } from '../core/3/room.service';
import { SiteService } from '../core/3/site.service';
import { UserChangeService } from '../core/3/user-change.service';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
export class AccountSelectorComponent implements OnInit, OnDestroy {
  public trimSite = trimSite;
  public trimOrganization = trimOrganization;

  public siteControl: FormControl;
  public userControl: FormControl;

  public sites: SiteDoc[] = [];
  public users: UserDoc[] = [];

  private siteKey: string;

  private destroySignal = new Subject<boolean>();

  constructor(
    private siteService: SiteService,
    private userService: UserService,
    public roomServcie: RoomService,
    private userChangeService: UserChangeService,
    private accountSelectorCreatedService: AccountSelectorCreatedService,
  ) { }

  ngOnInit(): void {
    this.accountSelectorCreatedService.notiAccountSelectorCreated();
    this.siteControl = new FormControl();
    this.userControl = new FormControl();

    this.initSiteUserList();
    this.observeSite();
    this.observeUser();
  }

  ngOnDestroy(): void {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  private observeSite() {
    combineLatest([this.siteControl.valueChanges, this.userService.observeCeoUser()])
      .pipe(takeUntil(this.destroySignal))
      .subscribe(([siteKey, users]) => {

        const site = this.siteService.sites[siteKey];
        if (!site) {
          console.error('No site');
          return;
        }

        const filteredUsers = users
          // .filter(user => this.roomServcie.rooms[user.room]?.site === site._id && !user.email.endsWith('toe.cloud'))
          .filter(user => this.roomServcie.rooms[user.room]?.site === site._id)
          .sort((a, b) => a.room < b.room ? -1 : 1);

        if (this.siteKey !== siteKey) {
          // 1. 사이트가 변경되었으면 최초 계정으로 변경한다.
          console.log(`*** site.valueChanges = ${siteKey}`);

          this.users = filteredUsers;
          if (this.users.length > 0) {
            this.siteKey = siteKey;
            this.userControl.setValue(this.users[0]);
          }
        } else {
          const oldUsers = JSON.stringify(this.users);
          const newUsers = JSON.stringify(filteredUsers);

          // 2. user DB 변경에 따른 지점별 계정 업데이트
          if (oldUsers !== newUsers) {
            this.users = filteredUsers;
            // console.log(`this.users = \n${this.users.map(user => user.email).join()}`);

            // DB가 변경되었다고 선택된 user를 변경하면 안된다.
            const oldId: string = this.userControl.value.email;
            const newUser = this.users.find(user => user.email === oldId);
            this.userControl.setValue(newUser ?? this.users[0]);
          }
        }
      });
  }

  private observeUser() {
    this.userControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe((user: UserDoc) => {
        console.log(`*** user.valueChanges = ${user.email}`);
        this.userChangeService.userChanged(user, 'UI에서 계정 변경');
      });
  }

  /**
   * 최신 site와 ceoStatUser 목록을 가져온다.
   * this.sites와 this.users를 초기화한다.
   */
  private initSiteUserList() {
    this.sites = Object.values(this.siteService.sites).sort((a, b) => a.siteNo < b.siteNo ? -1 : 1);

    if (this.sites.length > 0) {
      const currentUser = this.userService.user;
      const site = this.sites.find($site => $site.roomKeys.includes(currentUser.room));
      this.siteControl.setValue(site._id);

      this.userService.observeCeoUser()
        .pipe(take(1))
        .subscribe(users => {
          this.users = users
            .filter(user => user.room.includes(site._id) && !user.email.endsWith('toe.cloud'))
            .sort((a, b) => a.room < b.room ? -1 : 1);

          if (this.users.length > 0) {
            const user = this.users.find($user => currentUser.room === $user.room);
            this.userControl.setValue(user);
            this.userChangeService.userChanged(user, '최초 UI 설정');
          }
        });
    }
  }
}
