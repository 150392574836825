/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserDoc } from '../../schema/3/schema';

import { UserService } from '../2/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserChangeService {
  private currentUserEmail = '';
  private currentUserRoomKey = '';
  public userRoomKeySubject = new BehaviorSubject<string>(this.currentUserRoomKey);

  constructor(private userService: UserService) { }

  /**
   * 마스터 계정에서 user를 변경했을 때 수행하는 일
   * - 해당 user 딸린 계정의 businessStartDate를 가져온다.
   */
  public async userChanged(user: UserDoc, tag = '') {
    if (user.email !== this.currentUserEmail) {
      // 사용자가 변경이 되면 조회를 하기 전에 businuessStartDate가 변경이 되어야 한다.
      // 그래서 userService에서 subscribe()를 하지 않고 직접 호출한다.
      this.currentUserEmail = user.email;
      await this.userService.updateBusinessStartEndDateForCeoMaster(user);

      console.log(`(${tag}) CALLED userRoomKeySubject.next(${user.room})`);
      this.currentUserRoomKey = user.room;
      this.userRoomKeySubject.next(user.room);
    } else {
      console.log(`(${tag}) ${user.email}가 변하지 않았으니 무시한다.`);
    }
  }
}
