/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { sub } from 'date-fns';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { NoticeDocUI } from './schema/4/schema-ui';

import { GtagService } from './core/1/gtag.service';
import { AddressService } from './core/1/address.service';
import { UtilService } from './core/1/util.service';
import { roleMappings } from './core/1/string-map';
import { VersionService } from './core/1/version.service';
import { debugLog, sleep, trimOrganization } from './core/1/util';
import { AuthService } from './core/2/auth.service';
import { UserService } from './core/2/user.service';
import { RoomService } from './core/3/room.service';
import { SiteService } from './core/3/site.service';
import { UserChangeService } from './core/3/user-change.service';
import { NoticeService } from './core/4/notice.service';
import { LogService } from './core/4/log.service';
import { ConfService } from './core/5/conf.service';

import { DialogIframeService } from './shared/dialog-iframe/dialog-iframe.service';
import { environment } from '../environments/environment';
import { InitGuardService } from './init-guard.service';

const ghostkitchenItems = [
  { name: '청구서/정산내역 확인', route: '/monthly-invoice' },
  { name: 'divider' },
];

const commonItems = [
  { name: '이웃의 배민', route: '/unified-shop/baemin' },
  { name: '이웃의 배민1', route: '/unified-shop/baemin1' },
  { name: '이웃의 쿠팡이츠', class: 'new', route: '/unified-shop/coupangeats' },
  { name: 'divider' },
  { name: '원산지 정보', route: '/origin-description' },
  { name: '주문 내역', route: '/unified-order' },
  { name: '주문 통계', route: '/order-stat' },
  { name: '시간대별 통계', route: '/hour-stat' },
  { name: '요일별 통계', route: '/by-day-stat' },
  { name: '동별 주문 히스토리', route: '/daily-history' },
  { name: '메뉴별 판매 통계', route: '/excluding-option-menu-stat' },
  { name: '메뉴별 옵션조합 통계 ', route: '/including-option-menu-stat' },
  { name: 'divider' },
  { name: '통합 배송', route: '/unified-delivery' },
  // { name: '정산용 런투유|스파이더 배송', route: '/delivery-fee' },
  { name: 'divider' },
  { name: '울트라콜 주문 지도', route: '/daily-baemin-operating-ad-campaign/0' },
  { name: '배민 광고 현황', route: '/baemin-operating-ad-campaign' },
  { name: 'divider' },
  { name: '비밀번호 변경', route: '/change-password' },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;

  loggedIn = false;
  email = '';
  authSubscription: Subscription = null;
  versionSubscription: Subscription = null;
  userSubscription: Subscription = null;
  organizationConfSubscription: Subscription = null;
  roomNameSubscription: Subscription = null;
  myVersion: string;
  latestVersion: string;
  roomName: string; // '삼성점 14호'
  currentNavItemName: string; // '주문 상태'
  isPrintScreen = false;
  isInvoice = false;
  noticesToRead = 0;

  title = '발가락 사장님';
  isMaster = false;
  public navItems = commonItems;

  // 최초 접속한 경로명('/unified-order')를 기억한다.
  // '/'로 접속할 경우에는 바로 redirect로 다른 곳으로 이동하게 되고,
  // 리로드를 할 경우에 브라우저는 변경된 URL에 대해서 cache를 업데이트한다.
  // 이럴 경우에 '/'로 접속을 하면 '/'에 대한 캐시는 이전 버전이기 때문에 캐시 만료전까지 업데이트 버튼이 보이는 문제가 있다.
  // 이를 해결하기 위해서 최초 접속 경로명이 '/'인 경우에는 pushState()를 이용해서 '/'로 변경 후에 리로드를 해서 '/'에 대한 캐시가 변경이 되게 한다.
  initialPathname = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private confService: ConfService,
    private utilService: UtilService,
    private logService: LogService,
    private versionService: VersionService,
    private addressService: AddressService,
    private userService: UserService,
    private userChangeService: UserChangeService,
    private initGuardService: InitGuardService,
    private siteService: SiteService,
    private roomService: RoomService,
    private gtagService: GtagService,
    private noticeService: NoticeService,
    private dialogIframeService: DialogIframeService
  ) {
    const re = /^(\/[^/?]+)([^?]*)(?:\?(.+))?$/;

    this.initialPathname = window.location.pathname;
    console.log(`initialPathname = ${this.initialPathname}`);

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        // console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);
        this.gtagService.page(event.urlAfterRedirects);

        for (const navItem of this.navItems) {
          if (navItem.route === event.urlAfterRedirects) {
            this.currentNavItemName = navItem.name;
            break;
          }
        }

        const matches = re.exec(event.urlAfterRedirects);

        if (matches) {
          const prefix = matches[1]; // '/daily-baemin-operating-ad-campaign',

          if (prefix === '/monthly-invoice') {
            const documentNo = matches[3];
            this.isInvoice = true;
            if (documentNo) {
              this.isPrintScreen = true;
            }
          } else {
            this.isInvoice = false;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.authSubscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      })
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);

      this.loggedIn = value;
      if (value === true) {

        this.utilService.toastrInfo('로그인 성공!', null, 500);
        this.email = this.authService.user.email;

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.addressService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address');
        }
        const publicAddress = await this.addressService.findPublicAddress();

        this.userService.observe(this.email);
        while (this.userService.user === undefined) {
          debugLog('Waiting for the first sync with Firestore user');
          await sleep(200);
        }

        this.siteService.observe();

        this.roomService.observe();
        while (Object.keys(this.roomService.rooms).length === 0) {
          debugLog('Waiting for the first sync with Firestore room');
          await sleep(200);
        }

        const viewportDesc = window.visualViewport ? ('해상도 = '
          + window.visualViewport.width + 'x' + window.visualViewport.height
          + ' (확대비율 ' + Math.round(window.visualViewport.scale * 100) + '%)') :
          '해상도 모름';
        this.logService.info(`로그인 성공. version=${environment.version}, id=${this.email}, ${viewportDesc}, userAgent=${navigator?.userAgent}, remote=${publicAddress}, local=${localAddresses}`);

        // 이 후에는 conf/organization과 user/this.email에 대한 데이터를 불러왔음을 보장
        await this.initGuardService.promiseForInit();

        await this.userChangeService.userChanged(this.userService.user, '최초 로그인 성공');
        this.observeCurrentRoomNoForTitleAndNotice();

        // 다음의 순서로 필요한 정보를 읽어온다.
        // email => /user/email => message/doc (to.instanceId == user.room)
        this.userSubscription = this.userService.latestUserSubject.subscribe(user => {
          if (user) {
            if (user.role !== 'ceostat' && user.role !== 'ceo' && user.roleCeoMaster !== true) {
              // user를 두 번 부르지 않게 끊어줌
              this.userSubscription?.unsubscribe();
              this.logService.withToastrError(`사용 불가능한 역할(${user.role})로 접근했습니다. 7초 뒤 로그아웃 됩니다.`);
              this.utilService.ignore = true;
              setTimeout(() => this.logout(), 7000);
            }

            if (this.userService.user.roleCeoMaster === true) {
              this.isMaster = true;
            }

            if (this.userService.user.organization === 'ghostkitchen') {
              this.navItems = [...ghostkitchenItems, ...commonItems];
            }
          }
        });

        this.confService.observePrinterConf();
        this.confService.observeIndexConfs();

        this.observeNoticeToRead();
      }
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      if (environment.production) {
        // 개발 환경에서는 자동 업데이트 금지
        this.latestVersion = lastesVersion;
        if (this.latestVersion !== this.myVersion) {
          // 1분 뒤에 자동 리로드
          setTimeout(() => {
            this.reload();
          }, 60000);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.organizationConfSubscription) {
      this.organizationConfSubscription.unsubscribe();
    }
  }

  logout() {
    this.utilService.ignore = true;

    this.logService.info('로그아웃');
    this.authService.signOut().then(value => {
      // 로그아웃을 하면 기존 상태도 초기화해야 한다.
      // 여러 서비스가 갖고 있는 상태를 초기화하기 위해서 일단 reload()한다.
      // TODO : 나중에는 상태를 정리한다 리로드 할 경우에 토스트 메시지가 사라지는 문제가 있다.
      setTimeout(() => {
        // 앞에서 설명
        if (this.initialPathname === '/') {
          window.history.pushState('initial', '', '/');
        }
        window.location.reload();
      }, 1000);
    });
  }

  reload() {
    // 앞에서 설명
    if (this.initialPathname === '/') {
      console.log('업데이트가 /에 대해서 되도록 location을 변경합니다.');
      window.history.pushState('initial', '', '/');
    }
    window.location.reload();
  }

  get role() {
    return this.userService.user ? roleMappings[this.userService.user.role] : '...';
  }

  public openHelp() {
    this.dialogIframeService.openDialog('ceo-help/');
  }

  openManual() {
    window.open('https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/pos/toe_POS_manual_210707.pdf');
    // tslint:disable-next-line: max-line-length
    // window.open('https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/%EA%B3%A0%EC%8A%A4%ED%8A%B8%ED%82%A4%EC%B9%9C_%EC%9A%B4%EC%98%81%EC%A0%95%EC%B1%85/ver_1.1.5.pdf');
  }

  observeCurrentRoomNoForTitleAndNotice() {
    this.userChangeService.userRoomKeySubject.subscribe((roomKey) => {
      const roomName = trimOrganization(this.roomService.rooms[roomKey].name);
      this.title = `${this.loggedIn ? '[' + roomName + '] ' : ''}발가락 사장님`;
      document.title = `${this.loggedIn ? '발가락 사장님[' + roomName + '] ' : '발가락 사장님'}`;

      this.noticeService.observe(roomKey);
    });
  }

  /** (최근 2주간) 읽지않은 공지사항을 체크한다. */
  private observeNoticeToRead() {
    this.noticeService.lastestNoticeDocsSubject.subscribe(notices => {
      const startFrom = sub(Date.now(), { days: 14 });
      this.noticesToRead = notices
        .filter(notice => notice._timeCreate.toDate() > startFrom)
        .filter((notice: NoticeDocUI) => notice._ui.isRead === false).length;
    });
  }
}
