/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { RoomDoc, RoomDocs } from '../../schema/3/schema';

import { UserService } from '../2/user.service';

const collectionPath = 'room';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class RoomService {
  public rooms: RoomDocs = {};
  public latestSubject = new BehaviorSubject<RoomDocs>({});

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) {
  }

  public observe() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);

    const collection = this.db.collection<RoomDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      this.rooms = docs.reduce((acc, doc) => { acc[doc._id] = doc; return acc; }, {});
      this.latestSubject.next(this.rooms);
    });
  }
}
