/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { LogLevel } from '../../schema/1/schema-common';
import { LogOrderDoc } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';
import { instanceId } from '../1/common';
import { AddressService } from '../1/address.service';
import { UserService } from '../2/user.service';
import { RoomService } from '../3/room.service';
import { UserChangeService } from '../3/user-change.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService,
    private roomService: RoomService,
    private addressService: AddressService,
    private userChangeService: UserChangeService
  ) {
    console.log(`log instance ID = ${instanceId}`);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  private async send(level: LogLevel, message: string, email?: string) {
    try {
      // message Id는 firestore가 제공하는 Id를 이용한다.
      const docRef = this.db.firestore.collection('logOrder').doc();
      const docId = docRef.id;

      const organization = this.userService.organization;

      const roomKey = this.userService.user.room;
      const room = this.roomService.rooms[roomKey];

      const doc: LogOrderDoc = {
        _id: docId,
        _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

        organization,
        site: room.site,
        room: roomKey,
        orderId: 'room',

        instanceType: 'ceostat',
        instanceNo: instanceId,
        account: email ? email : this.userService?.user?.email ?? '',

        publicIP: this.addressService.publicAddress ?? '',

        level: level ?? 'info',
        message
      };

      return await this.db.doc<LogOrderDoc>(docRef).set(doc);
    } catch (error) {
      console.error('로그 전송 중 에러 발생 :', error);
    }
  }

  public async debug(message: string, email?: string) {
    await this.send('debug', message, email);
  }
  public async info(message: string, email?: string) {
    await this.send('info', message, email);
  }
  public async warn(message: string, email?: string) {
    await this.send('warn', message, email);
  }
  public async error(message: string, email?: string) {
    await this.send('error', message, email);
  }

  public async withToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    await this.send('error', `${title ? title + ': ' : ''} ${message}`);
  }

  public async withToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    await this.send('error', msg);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  public async logRoom(message: string, level: LogLevel = 'info') {
    const room = this.userChangeService.userRoomKeySubject.getValue();
    const site = this.roomService.rooms[room].site;
    const organization = this.userService.organization;

    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection('logOrder').doc();
    const docId = docRef.id;

    const doc: LogOrderDoc = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

      organization,
      site,
      room,
      orderId: 'room',

      instanceType: 'ceostat',
      instanceNo: instanceId,
      account: this.userService?.user?.email ?? '',

      publicIP: this.addressService.publicAddress ?? '',

      level: level ?? 'info',
      message
    };

    try {
      if (doc.room === undefined) {
        return this.send('error', `logOrder를 남기는데 room이 없습니다. ${JSON.stringify(doc)}`);
      }

      return await this.db.doc<LogOrderDoc>(docRef).set(doc);
    } catch (error) {
      console.error('logOrder set()에서 예외 발생:', error);
    }
  }

  public logRoomWithToastrWarn(message: string, title?: string, timeout?: number) {
    this.utilService.toastrWarning(message, title, timeout);
    return this.logRoom(`${title ? title + ': ' : ''} ${message}`, 'warn');
  }

  public logRoomWithToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    return this.logRoom(`${title ? title + ': ' : ''} ${message}`, 'error');
  }

  public logRoomWithToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.logRoom(msg, 'error');
  }
}
