<h3 *ngIf="myVersion != latestVersion"><span class="red">1분 후 새 버전으로 자동 업데이트됩니다.</span><span class="version">(현재 버전:{{myVersion}}, 최신 버전:{{latestVersion}})</span></h3>

<mat-toolbar color="primary" *ngIf="!isPrintScreen">
  <button id='menuButton' mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <h1><span class='hideUnder480Width'>{{title}} {{this.currentNavItemName ? '- ' : ''}}</span> {{this.currentNavItemName}}</h1>
  <h1 class='hideUnder768Width' *ngIf="isMaster"> (마스터 계정 사용 중)</h1>
  <div class="right">
    <button mat-raised-button *ngIf="myVersion != latestVersion" color="accent" (click)="reload()">수동 업데이트</button>
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>help</mat-icon></button>
    <mat-menu class="menu-buttons" #menu="matMenu">
      <span mat-menu-item *ngIf="loggedIn == true">{{ email }} [{{ role }}]</span>
      <mat-divider></mat-divider>
      <span mat-menu-item class="version">현재 버전:{{myVersion}}</span>
      <span mat-menu-item class="version" *ngIf="myVersion != latestVersion">최신 버전:{{latestVersion}}</span>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" disableClose>
    <button mat-stroked-button class="help" color="accent" (click)="openHelp()">메뉴 설명 보기</button>
    <mat-nav-list>
      <a mat-list-item (click)="openManual()">발가락 가이드북</a>
      <a mat-list-item routerLinkActive #routerLinkActiveInstance="routerLinkActive"
        [class.active]="routerLinkActiveInstance.isActive" routerLink="/notice">공지사항 <span *ngIf="noticesToRead > 0" class="no-read">{{ noticesToRead }}</span></a>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let navItem of navItems">
        <div *ngIf="navItem.name != 'divider'">
          <a *ngIf="navItem.route != '/auth/signup' || loggedIn == false" mat-list-item
            [class]="navItem.class"
            routerLinkActive="sidenav-item-selected"
            #routerLinkActiveInstance="routerLinkActive"
            [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1" [routerLink]="[navItem.route]">
            {{ navItem.name }}
          </a>
        </div>
        <mat-divider *ngIf="navItem.name === 'divider'"></mat-divider>
      </ng-container>
      <a mat-list-item (click)="logout()">로그아웃</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="reload()">재시작</a>
    </mat-nav-list>
  </mat-sidenav>

  <div class="container">
    <app-account-selector *ngIf="isMaster"></app-account-selector>
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
