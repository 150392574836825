/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface DialogData {
  content: string;
}
@Component({
  selector: 'app-guide-dialog',
  templateUrl: './guide-dialog.component.html',
  styleUrls: ['./guide-dialog.component.scss']
})
export class GuideDialogComponent {

  guideContent: SafeHtml;

  constructor(
    private dialogRef: MatDialogRef<GuideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    sanitizer: DomSanitizer
  ) {
    this.guideContent = sanitizer.bypassSecurityTrustHtml(data.content);
  }

  close() {
    this.dialogRef.close();
  }
}
