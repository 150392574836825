/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogIframeComponent } from './dialog-iframe.component';

@Injectable({
  providedIn: 'root'
})
export class DialogIframeService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(endpoint: string) {
    return this.dialog.open(DialogIframeComponent, {
      disableClose: false,
      width: '95vw',
      height: '95vh',
      data: {
        endpoint
      }
    });
  }
}
