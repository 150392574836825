/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// refer: https://www.ag-grid.com/javascript-grid-set-license/#setting-the-license-key
import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey('CompanyName=Buttonsoup,LicensedGroup=GhostKitchen,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-011203,ExpiryDate=15_October_2021_[v2]_MTYzNDI1MjQwMDAwMA==ac35d4d3c0893030e74d1d65773dca2b');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
