/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import cloneDeep from 'lodash-es/cloneDeep';
import { format, parse, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

import { UnifiedOrderFood } from '../../schema/1/schema-common';
import { UnifiedOrderDoc } from '../../schema/3/schema';
import { UnifiedOrderFoodUI } from '../../schema/4/schema-ui';

import { environment } from '../../../environments/environment';

// input에 대한 형식과 캘린더 월 셀렉트박스 형식
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM'
  }
};

// 요일 및 월 표시 형식을 한글로 표시한다.
export const MY_LOCALE = {
  monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
};

/**
 * 사업자 번호 형식을 만든다.
 */
export function normalizingBusinessNumber(businessNumber: string) {
  if (typeof businessNumber !== 'string') {
    return businessNumber;
  }
  // 1. 숫자 이외에는 모두 제외한다.
  // 2. 3-2-5+로 분리한다.
  // 10자리가 되지 않도라도 match 된다.
  // 10자리가 넘는 숫자는 자르지 않고 뒤에 붙인다.
  const [, ...groups] = businessNumber.replace(/[^0-9]/g, '').match(/^(\d{0,3})(\d{0,2})(\d*)$/);
  return groups.filter(group => group !== '').join('-');
}

/**
 * '고스트키친 삼성점 04호'를 '삼성점 04호'로 변환한다.
 */
export function trimOrganization(text: string) {
  return text?.replace(/^\S+\s+/, '');
}

/**
 * '고스트키친 삼성점 04호'를 '04호'로 변환한다.
 */
export function trimSite(text: string) {
  const matches = text?.match(/\s(\d+호)$/);
  if (matches) {
    return matches[1];
  }
  return text;
}

/**
 *
 * @param dateStr  '2018-01-01T12:34:56+0900'
 */
export function weekdayKR(dateStr: string): string {
  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  return format(parseISO(dateStr), 'ccc', { locale: ko });
}

/**
 * 여러 형태의 시간을 ISO 형식의 문자열로 변환한다.
 * date2iso.test.ts에 사용예 확인
 */
export function toDate(date: string | number | Date): Date {
  if (date === undefined) {
    throw TypeError(`undefined date format @ toDate()`);
  }

  if (date instanceof Date) {
    return date;
  } else if (typeof date === 'number') {
    if (date > 9999999999) {
      // 밀리초라면
      return new Date(date);
    } else {
      // 초라면
      return new Date(date * 1000);
    }
  } else {
    // Case 0. '2019-05-03T12:08:38+0900'
    let match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}):?(\d{2})$/);
    if (match) {
      return parseISO(date);
    }

    // Case 1-1. '2019-05-03 12:08:38'
    // Case 1-2. '2019-05-03 12:08:38.0'
    match = date.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.0)?$/);

    if (match) {
      return parseISO(`${match[1]}T${match[2]}+09:00`);
    }

    // Case 2.
    match = date.match(/^(\d{4}\d{2}\d{2})T(\d{2}\d{2}\d{2})Z/);

    if (match) {
      return parse(`${match[1]}T${match[2]}+00:00`, `yyyyMMdd'T'HHmmssXXX`, new Date());
    }

    // Case 3. 1559097490
    // 단위가 초라면 10자가 될 것이다.
    match = date.match(/^\d{10}$/);
    if (match) {
      return new Date(parseInt(date, 10) * 1000);
    }

    // 단위가 밀리초라면 13자가 될 것이다.
    match = date.match(/^\d{13}$/);
    if (match) {
      return new Date(parseInt(date, 10));
    }
  }

  throw TypeError(`Unexpected date format : ${date}`);
}

/**
 *  UnifiedOrderDoc의 메뉴와 가격 부분만 생성한다.
 *
 *  order1 + order2
 */
function appendOrder(order1: Partial<UnifiedOrderDoc>, order2: UnifiedOrderDoc, ignoreZeroOption = false) {
  if (order1.foods === undefined) {
    order1.foods = [];
  }

  // foods의 개별 food를 변경하기 때문에 copy를 한다.
  let foods: UnifiedOrderFoodUI[] = cloneDeep(order1.foods).concat(cloneDeep(order2.foods));

  // 1. 0원 옵션 무시 및 옵션을 food로 승격
  if (ignoreZeroOption) {
    const optFoods: UnifiedOrderFoodUI[] = [];
    for (const food of foods) {
      // 1. 0원 옵션 제거, foodOrdPrice는 변동없음
      food.foodOpts = food.foodOpts.filter(foodOpt => foodOpt.optPrice > 0);

      // 2. 옵션을 food로 승격 (이름 앞에 '+ ' 추가)
      food.foodOpts.slice(1).forEach(foodOpt => {
        optFoods.push({
          foodName: foodOpt.optName ? foodOpt.optName : '-',
          menuType: 'option',
          foodOpts: [{
            optName: '',
            optPrice: foodOpt.optPrice,
            optQty: foodOpt.optQty,
          }],
          foodQty: food.foodQty * foodOpt.optQty,
          foodOrdPrice: food.foodQty * foodOpt.optQty * foodOpt.optPrice, // foodQty까지 포함한 금액
          mergedName: ''
        });
      });

      if (food.foodOpts.length > 1) {
        // 가격있는 옵션들이 메뉴로 이사갔을 수 있으므로 기존 foodOrdPrice도 재조정되어야 한다.
        food.foodOrdPrice = food.foodQty * food.foodOpts[0].optQty * food.foodOpts[0].optPrice;

        // 잘라낸다.
        food.foodOpts.length = 1;
      }
    }

    foods = foods.concat(optFoods);
  }

  // 2. mergedName 생성
  // foodName과 여러 optName을 다음 3가지로 분리한다.
  //
  // mergedName | mergedZeroOptionName | mergedOptionName
  //
  for (const food of foods) {
    // 공백 제거
    food.foodName = food.foodName.trim();

    if (food.foodOpts.length > 0) {
      // 최초 foodOpts는 메뉴를 대표한다.
      const firstOpt = food.foodOpts[0];

      // 보통은 optName이 없지만 간혹 있는 경우가 있다.
      // 예) 치즈폭탄 떡볶이_1인분
      food.mergedName = firstOpt.optName === '' ? food.foodName : `${food.foodName}_${firstOpt.optName.trim()}`;

      // 이후 옵션 중에 가격이 없는 옵션 (선택옵션)
      // 보통 맛 선택과 같은 경우는 가격이 없다.
      food.mergedZeroOptionName = (food.foodOpts.length > 1) ? food.foodOpts
        .slice(1)
        .filter(foodOpt => foodOpt.optPrice === 0)
        .map(foodOpt => `[${foodOpt.optName ? foodOpt.optName.trim() : '-'}]`)
        .join(' ') : '';

      // 이후 옵션 중에 가격이 있는 옵션 (추가옵션)
      // 추가 메뉴와 같다.
      food.mergedOptionName = (food.foodOpts.length > 1) ? food.foodOpts
        .slice(1)
        .filter(foodOpt => foodOpt.optPrice !== 0)
        .map(foodOpt => `[${foodOpt.optName ? foodOpt.optName.trim() : '-'}]`)
        .join(' ') : '';
    } else {
      // 이런 경우가 있을 수 있나???
      food.mergedName = food.foodName;
    }
  }

  // 3. 동일한 옵션 구성은 하나로 merge 한다.
  const keyedFoods: {
    [longkey: string]: UnifiedOrderFood
  } = {};

  for (const food of foods.reverse()) {
    // ignoreZeroOptions인 경우에는 이름과 menuType(메인 | 옵션)으로 비교하고,
    // 그렇지 않은 경우에는 mergedZeroOptionName과 mergedOptionName으로 비교한다.
    // 중간 공백도 무시하고 비교한다.
    const longkey = ignoreZeroOption ?
      food.mergedName.replace(/ /g, '') + food.menuType :
      // `${food.mergedName}${food.foodOpts.map(opt => String(opt.optPrice)).join('-').replace(/ /g, '')}`;
      `${food.mergedName}/${food.mergedZeroOptionName}/${food.mergedOptionName}`.replace(/ /g, '');

    // 기존 메뉴가 존재해서 합하는 경우
    const keyedFood = keyedFoods[longkey];
    if (keyedFood) {
      keyedFood.foodQty += food.foodQty;
      // keyedFood.foodOrdPrice = keyedFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * keyedFood.foodQty;
      keyedFood.foodOrdPrice += food.foodOrdPrice;
    } else {
      keyedFoods[longkey] = food;
    }
  }

  foods = Object.values(keyedFoods);

  // 4. 가격이 비싼 메뉴가 앞에 위치하도록
  const sorted = foods.sort((food1: UnifiedOrderFood, food2: UnifiedOrderFood) => {
    const price1 = food1.foodOrdPrice; // foodQty가 포함된 가격
    const price2 = food2.foodOrdPrice;

    // 이름 > 가격 기준으로 정렬한다.
    if (food1.foodName < food2.foodName) {
      return -1;
    } else if (food1.foodName > food2.foodName) {
      return 1;
    } else {
      if (price1 > price2) {
        return -1;
      } else if (price1 < price2) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const mergedOrder: Partial<UnifiedOrderDoc> = {
    foods: sorted
  };

  return mergedOrder;
}

/**
 * 모든 주문 내용을 하나로 합한다.
 */
export function mergeOrders(orders: UnifiedOrderDoc[], ignoreZeroOption = false) {
  return orders.reduce<Partial<UnifiedOrderDoc>>((acc, order) => {
    return appendOrder(acc, order, ignoreZeroOption);
  }, {});
}

/**
 * Math.max(...)의 string 버전
 *
 * maxString('2020-01-31', '2020-02-01', '2019-01-31') => '2020-02-01'
 */
export function maxString(...strs: string[]) {
  return strs.sort((a, b) => a < b ? 1 : a > b ? -1 : 0)[0];
}

/**
 * Math.min(...)의 string 버전
 *
 * maxString('2020-01-31', '2020-02-01', '2019-01-31') => '2019-01-31'
 */
export function minString(...strs: string[]) {
  return strs.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)[0];
}

// 청구 조회기간 표시
export function getBillingDate(billingDate: Date) {
  // 청구월의 전월 1일, 말일
  const billingStartDate = format(new Date(billingDate), 'yyyy-MM-dd');

  const monthEndDateTime = new Date(billingDate.getFullYear(), billingDate.getMonth() + 1, 0);
  const billingEndDate = format(monthEndDateTime, 'yyyy-MM-dd');

  return { billingStartDate, billingEndDate };
}

/**
 *
 * 실제 데이터 조회 기간
 *
 * @param effectiveYear 'YYYY'
 * @param effectiveMonth 'MM'
 * @param businessStartDate 'YYYY-MM-DDTHH:mm:ss+0900'
 * @param businessEndDate 'YYYY-MM-DDTHH:mm:ss+0900'
 */
export function getSearchDate(effectiveYear: number, effectiveMonth: number, businessStartDate: string, businessEndDate: string) {
  const effectiveStartDateString = `${effectiveYear}-${String(effectiveMonth).padStart(2, '0')}-01`;
  const effectiveEndDateString = format(new Date(effectiveYear, effectiveMonth, 0), 'yyyy-MM-dd');

  const businessStartDateString = businessStartDate ? businessStartDate.substr(0, 10) : '1000-01-01';
  const businessEndDateString = businessEndDate ? businessEndDate.substr(0, 10) : '3000-01-01';

  return {
    startDate: maxString(effectiveStartDateString, businessStartDateString),
    endDate: minString(effectiveEndDateString, businessEndDateString)
  };
}

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

const diffTimestampInstances: {
  [instanceKey: string]: [number, number];
} = {};
/**
 * 실행하는 시점 간의 시간 차이를 구할 때 사용한다.
 */
export function diffTimestamp(instanceKey: string) {
  const nowMilli = Date.now();
  if (diffTimestampInstances[instanceKey] === undefined) {
    diffTimestampInstances[instanceKey] = [nowMilli, 0];
  }

  // 1. get old time
  const [oldTimestamp, oldCount] = diffTimestampInstances[instanceKey];
  // 2. calculate diffTIme
  const diffMilli = nowMilli - oldTimestamp;

  const sec = Math.floor(diffMilli / 1000);
  const milli = String(diffMilli % 1000);

  // 3. update oldTIme
  diffTimestampInstances[instanceKey] = [nowMilli, oldCount + 1];

  return `[${oldCount + 1}] ${sec}.${milli.padStart(3, '0')}`;
}

export function debugLog(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.log(msg);
  }
}
