/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { unifiedOrderVendorMappings } from '../../core/1/string-map';

@Component({
  selector: 'app-vendor-with-logo-renderer',
  templateUrl: './vendor-with-logo-renderer.component.html',
  styleUrls: ['./vendor-with-logo-renderer.component.scss']
})
export class VendorWithLogoRendererComponent implements ICellRendererAngularComp {
  public key: string;
  public vendor: string;

  constructor() { }

  agInit(params: ICellRendererParams) {
    this.key = params.value;
    this.vendor = unifiedOrderVendorMappings[this.key];
  }

  refresh(params: ICellRendererParams) {
    this.key = params.value;
    this.vendor = unifiedOrderVendorMappings[this.key];

    // 성공적으로 갱신했음을 grid에 알린다.
    return true;
  }
}
