/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { Component } from '@angular/core';

@Component({
  selector: 'app-day-renderer',
  templateUrl: './day-renderer.component.html',
  styleUrls: ['./day-renderer.component.scss']
})
export class DayRendererComponent implements ICellRendererAngularComp {
  public date: string;
  public day: string;

  constructor() { }

  agInit(params: ICellRendererParams) {
    // ['09-09', '월']
    const dateWithDay = params.value?.split(' ');
    this.date = dateWithDay?.[0];
    this.day = dateWithDay?.[1];
  }

  refresh() {
    return false;
  }
}
