/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountSelectorCreatedService {
  public accountSelectorCreatedSubject = new Subject<string>();

  constructor() { }

  public notiAccountSelectorCreated() {
    this.accountSelectorCreatedSubject.next('created');
  }
}
