/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dialog-iframe',
  templateUrl: './dialog-iframe.component.html',
  styleUrls: ['./dialog-iframe.component.scss']
})
export class DialogIframeComponent implements OnInit {

  public url: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { endpoint: string },
    public dialogRef: MatDialogRef<DialogIframeComponent>,
    private sanitizer: DomSanitizer,
  ) {
    const project = environment.firebase.projectId === 'toe-prod' ? '' : '-dev';
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://toe-page${project}.web.app/gk/${this.data.endpoint}?ts=${Date.now()}`);
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef?.close();
  }
}
