<div class="calendar-area">
  <span>조회 기간(최대 {{ this.rangeLimitText }}) : </span>

  <input
    placeholder="시작일"
    #startDateTimePicker
    [formControl]="startDateTimeControl"
    [ngClass]="displayTime ? 'displyTimeInput' : 'hiddenTimeInput'"
    (click)="showDateTimeRangePicker('start')"
    [matTooltip]="dateToString(searchDateTime.startDateTime)"
    [matTooltipClass]="'tooltip'"
    [matTooltipShowDelay]="400"
    readonly
  />
  <span>~</span>
  <input
    placeholder="종료일"
    #endDateTimePicker
    [formControl]="endDateTimeControl"
    [ngClass]="displayTime ? 'displyTimeInput' : 'hiddenTimeInput'"
    (click)="showDateTimeRangePicker('end')"
    [matTooltip]="dateToString(searchDateTime.endDateTime)"
    [matTooltipClass]="'tooltip'"
    [matTooltipShowDelay]="400"
    readonly
  />
  <button
    mat-raised-button
    color="primary"
    (click)="onSearchButtonClick()"
    [disabled]="isDateTimeEmitDisabled"
  >
    적용
  </button>

  <!--  input으로 날짜를 수정하더라도 button-toggle-group의 값이 바뀌지 않기 때문에 오늘->input수정 ->오늘 순서로 클릭하게되면 change이벤트가 발생하지 않아 click이벤트로 변경하였다.
    토글 그룹에서의 click 이벤트는 value를 전달하지 않아 각 버튼에 따로 이벤트를 달았다.-->
  <mat-button-toggle-group name="terms" class="term-button">
    <mat-button-toggle *ngFor="let button of buttons" (click)="setQuickButtonRange(button)">
      {{ button.buttonName }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
