/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

export type ProgressBarRendererComponentParams = ICellRendererParams & {
  /** 일별 매출액에서 행정동이 차지하는 비율 */
  percentage: number;
  /** 전체에서 가장 큰 매출이 100이 된다 */
  barWidthPercent: number;
  showBarGraph: boolean;
  showPercentage: boolean;
};

@Component({
  selector: 'app-progress-bar-renderer',
  templateUrl: './progress-bar-renderer.component.html',
  styleUrls: ['./progress-bar-renderer.component.scss']
})
export class ProgressBarRendererComponent implements ICellRendererAngularComp {
  public value: string;
  public percentage: string;
  public style: string;
  public showBarGraph = false;
  public showPercentage = false;

  constructor() {}

  agInit(params: ProgressBarRendererComponentParams) {
    this.setProgressBar(params);
  }

  /**
   * 셀의 data가 변경이 되었을 때 ag-grid가 다시 그릴지(false)
   * 아니면 직접 다시 그릴지(true)를 알려준다.
   *
   * false를 리턴하면 component를 파괴 후에 다시 생성한다. Data만 변경이 되었을 때도 모든 DOM을 다시 생성하게 된다.
   *
   * 동적으로 데이터가 변경이 되는 경우가 아니라면 이 함수 자체가 불리지는 않는다.
   * 셀이 화면에 노출되지 않는 경우에 메모리를 절약하기 위해서 제거하는 경우는 refresh 대상이 아니다.
   *
   */
  refresh() {
    return false;
  }

  private setProgressBar(params: ProgressBarRendererComponentParams) {
    this.value = params.value ? new Intl.NumberFormat().format(params.value) : '0';
    this.percentage = params.percentage.toFixed(1) + '%';
    this.showBarGraph = params.showBarGraph;
    this.showPercentage = params.showPercentage;

    this.style = `width: ${params.barWidthPercent}%;`;
  }
}
