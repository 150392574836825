<div class="container">
  <mat-calendar
    #calendar
    [selected]="selectedDateTime.date"
    [startAt]="selectedDateTime.date"
    (selectedChange)="selectedChange($event)"
  ></mat-calendar>

  <mat-selection-list
    *ngIf="selectedDateTime.useTimeSelector"
    class="selectionList"
    [multiple]="false"
    (selectionChange)="onChangeTime($event)"
  >
    <mat-list-option
      *ngFor="let hh of hours"
      [value]="hh"
      [selected]="selectedHour === hh"
    >
      {{ hh }}:00
    </mat-list-option>
  </mat-selection-list>
</div>
