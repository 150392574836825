/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// 조회 기간
export interface SearchDate {
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class SearchTermService {

  date: SearchDate = {
    startDate: new Date(),
    endDate: new Date()
  };

  searchDateSubject = new BehaviorSubject<SearchDate>(this.date);
  constructor() { }
}
